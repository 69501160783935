// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
import React from 'react'
import PropTypes from 'prop-types'
import { Nav, Navbar, NavItem } from 'react-bootstrap'
import { connect } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { isFunction } from 'lodash-es'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import EmployerAlertBadge from 'syft-acp-core/entities/EmployerDetail/EmployerAlertBadge'

import './SubNavigation.css'

/** List of submenus used by this component. */
const subLists = (isInsuranceClassCodesOn, isAdminClientPreferencesOn, isNegativeAction) => ({
  agencies: [
    {
      to: ({ agencyID }) => `/agencies/view/${agencyID}`,
      name: 'Agency',
      exact: true,
    },
    {
      to: ({ agencyID, agencyName }) =>
        `/finance/rate-cards/agency?agency=${agencyID}${agencyName ? `&agencyName=${agencyName}` : ''}`,
      name: 'Rate cards',
    },
    {
      to: ({ agencyID }) => `/agencies/view/${agencyID}/accounts`,
      name: 'Accounts',
    },
  ],
  worker: [
    {
      to: ({ workerID }) => `/entity/workers/view/${workerID}`,
      name: 'Worker',
      exact: true,
    },
    {
      to: ({ workerID }) => `/entity/workers/view/${workerID}/onboarding`,
      name: 'Onboarding',
    },
    isNegativeAction
      ? {
          to: ({ workerID }) => `/entity/workers/view/${workerID}/negative-actions`,
          name: 'Levels Negative Actions',
        }
      : {
          to: ({ workerID }) => `/entity/workers/view/${workerID}/strikes`,
          name: 'Strikes',
        },

    {
      to: ({ workerID }) => `/entity/workers/view/${workerID}/networks`,
      name: 'Networks',
    },
    {
      to: ({ workerID }) => `/entity/workers/view/${workerID}/compliance`,
      name: 'Onboarding',
    },
  ],
  employers: [
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}`,
      name: 'Employer',
      exact: true,
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/venues`,
      name: 'Venues',
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/networks`,
      name: 'Networks',
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/accounts`,
      name: 'Accounts',
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/ratings`,
      name: 'Ratings',
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/alerts`,
      // eslint-disable-next-line react/prop-types
      name: ({ employerID }) => (
        <span>
          Alerts
          <EmployerAlertBadge employerID={employerID} />
        </span>
      ),
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/history`,
      name: 'History',
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/blocklist`,
      name: 'Client blocklist',
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/salesforce`,
      name: 'Salesforce',
      forceReload: true,
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/ratecards-v2`,
      name: 'Rate cards',
    },
    {
      to: ({ employerID }) => `/entity/employers/view/${employerID}/commissions`,
      name: 'Commissions',
    },
    isAdminClientPreferencesOn
      ? {
          to: ({ employerID }) => `/entity/employers/view/${employerID}/preferences`,
          name: 'Fulfilment',
        }
      : null,
    isInsuranceClassCodesOn
      ? {
          to: ({ employerID }) => `/entity/employers/view/${employerID}/insurance-class-codes`,
          name: 'Insurance class codes',
        }
      : null,
  ].filter(Boolean),
  bizdev: [
    { to: '/bizdev/conversion', name: 'Conversion' },
    { to: '/marketing/users', name: 'Marketing users' },
    // { to: '/marketing/codes', name: 'Referral codes' }
  ],
  watchlist: [
    { to: '/watchlist/alerts', name: 'Alerts' },
    { to: '/watchlist/conversations/list', name: 'Conversations' },
    { to: '/watchlist/documents', name: 'Documents' },
    { to: '/watchlist/feedback', name: 'Feedback' },
    // { to: '/watchlist/health', name: 'Health questionnaires' }
  ],
  finance: [
    { to: '/finance/invoicing', name: 'Invoicing' },
    { to: '/finance/payments', name: 'Payments' },
    { to: '/finance/overpayments', name: 'Overpayments' },
  ],
  conduct: [
    { to: '/conduct/appeals', name: 'Appeals' },
    { to: '/conduct/conduct-reviews', name: 'Conduct reviews' },
  ],
  currentUser: [
    { to: ({ userID }) => `/entity/users/view/${userID}`, name: 'Profile' },
    { to: '/settings', name: 'Settings' },
  ],
})

export const isReloadLinkActive = (configUrlFn, browserUrl) => {
  const configUrl = configUrlFn({})
  const configUrlRegex = new RegExp(configUrl.replace('/', '\\/').replace(/undefined/g, '\\d+'))
  return !!browserUrl.match(configUrlRegex)
}

const AdminNavigation = ({ countryCode, subPage, data, ...rest }) => {
  const isAdminClientPreferencesOn = useFlexFlagIsOn('admin_client_preferences')
  const isNegativeAction = useFlexFlagIsOn('pte_34577_negative_actions')
  const isInsuranceClassCodesOn = countryCode === SUPPORTED_COUNTRY_CODES.US

  const subList =
    subLists(isInsuranceClassCodesOn, isAdminClientPreferencesOn, isNegativeAction)[subPage] || []
  const poly = func => (isFunction(func) ? func(data) : func)

  const linkContainer = (n, to, name, exact) => (
    <LinkContainer exact={exact} key={String(n)} to={poly(to)}>
      <NavItem>{poly(name)}</NavItem>
    </LinkContainer>
  )

  /**
   * reloadLink will generate a link that does not go through react-router, and
   * therefore will perform a full page load when loading the linked URL. This
   * is done to work around an issue with data caching that prevents the
   * Employer/Salesforce page in particular from loading correctly the first
   * time it is visited.
   *
   * Please see the following Jira issues for reference:
   * - https://syftapp.atlassian.net/browse/INTOPS-622
   * - https://syftapp.atlassian.net/browse/INTOPS-377
   *
   * REVIEW: This workaround must be removed once refactoring of the data
   * fetching system has been completed.
   *
   * @param {number} n - the index of the item in the navigation, used as key
   * @param {string} to - the URL to link to
   * @param {string} name - the label to be displayed in the link
   */

  const reloadLink = (n, to, name) => (
    <li
      key="{String(n)}"
      role="presentation"
      className={isReloadLinkActive(to, global.location.href) ? 'active' : ''}
    >
      <a href={poly(to)}>{poly(name)}</a>
    </li>
  )

  const subItems = subList.map(({ to, name, forceReload, exact }, n) => {
    if (!!forceReload) {
      return reloadLink(n, to, name)
    } else {
      return linkContainer(n, to, name, exact)
    }
  })

  return (
    <Navbar staticTop componentClass="header" className="sub-nav" role="banner" {...rest}>
      <Navbar.Header>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse className="bs-navbar-collapse" role="navigation">
        <Nav role="navigation">{subItems}</Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

AdminNavigation.propTypes = {
  subPage: PropTypes.string.isRequired,
  data: PropTypes.object,
}

AdminNavigation.defaultProps = {
  data: {},
}

export default connect(state => ({
  countryCode: state?.app?.countryCode,
}))(AdminNavigation)
